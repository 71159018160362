import React from 'react';
import { useSession } from 'app/hooks/useSession';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

export default function EnvironmentSelector() {
  const { connections, dbNumber, ssoToken } = useSession();
  const { t } = useTranslation();

  const sso = process.env.REACT_APP_SSO_API_URL;
  const currentConnection = connections?.find(item => item.db_number == dbNumber);

  const handleSelectConnection = async event => {
    const id = event.target.value;
    if (id && dbNumber !== id) {
      const connection = connections.find(item => item.db_number === id);

      const redirectURL = `${connection.host_url}/login-callback?token=${ssoToken}&db_number=${connection?.db_number}`;
      navigate(redirectURL);
    }
  };

  const options = [
    ...(connections?.map(connection => ({
      id: connection?.db_number,
      name: connection?.name
    })) || [])
  ];

  if (sso && connections?.length > 1)
    return (
      <select
        className="form__select form__select--outline"
        id="environment"
        name="environment"
        value={currentConnection?.db_number}
        onChange={handleSelectConnection}
      >
        {options?.map(option => (
          <option
            key={option.id}
            value={option.id}
          >
            {option.name}
          </option>
        ))}
      </select>
    );

  return null;
}
